.custom-modal .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4e4e4e;
    text-transform: uppercase;
  }
  
  .modal-body {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .user-info {
    font-size: 1rem;
    color: #5a5a5a;
  }
  
  .user-icon {
    font-size: 2rem;
    color: #007bff;
  }
  
  .question-container {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .question-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .question-button.btn-default {
    background-color: #eaeaea;
    color: #333;
  }
  
  .question-button.btn-saved {
    background-color: #28a745;
    color: #fff;
  }
  
  .question-button.btn-marked {
    background-color: #007bff;
    color: #fff;
  }
  
  .question-button.btn-visited {
    background-color: #dc3545;
    color: #fff;
  }
  
  .question-button:hover {
    transform: scale(1.1);
  }
  
  .submit-button {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 12px;
    border: none;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  #button_plate:hover {
    opacity: 0.85;
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }

  

  .fade-in-menu {
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  
  .fade-out-menu {
    animation: fadeOut 0.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
  
  #toggle_button{
    float: right;
    margin-top: 5px;
    border-color: none;
    color: rgb(24, 23, 23);
    background-color: white;

  }
  #year{
    color: #9c0895;
    
  }

  