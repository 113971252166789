#dashboard{
    
    margin-top:20px;
    margin-left: 10px;
    background-color: white;
}
#dashboard_img{
    border-radius: 50%;
    text-align: center;
}
#add_series{
    text-align: center;
}
#ser_container{
  margin-top: 30px;
  font-family: 'Times New Roman', Times, serif;
 
}
#poster{
  padding: 5px 10px 5px 10px;
}
#myseries_card{
    box-Shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
    margin: 20px 0 0 5px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif;
   
}
#myseries_carde{
    margin: 0px 0px 0 0px;
    background-color: rgb(210, 241, 236);
    font-weight: 400;
    padding: 10px;
   
   
   
}
#myseries_cardb{
    background-color: rgb(119, 116, 116);
    color: black;
    
}
#title{
    color: #252525;
}
#title1{

padding: 10px;
}
.green-border {
    border-bottom: 2px solid green;
  }
  
  .red-border {
    border-bottom: 2px solid red;
  }
 
  #top_header{
    border: rgb(119, 116, 116);
    background-color: rgb(227, 230, 233);
    padding: 3px;
    padding-left: 15px;
    padding-top: 5px;
  }
  #top_header1{
    border-bottom: 1px solid rgb(236, 233, 233);
    padding-top: 3px;
    padding-bottom: 3px;
    color: #118585;
    font-weight: 800;
    margin-top: 10px;
   

    
  }
  #section_bar{
    border-radius: 10px;
   height: 25px;
   padding: 0px;
   margin-left: 2px;
   padding-left: 10px;
   padding-right: 10px;
   color: #118585;
   font-weight: 600;
    

  }
  #question_d{
    color: black;
    margin-top: 30px;
    margin-left: 0px;
  }

  #timer{
    background-color: aliceblue;
    color:#118585;
    margin-right: 35px;
    padding:8px;
    font-weight: 800;
  }
  #button_div{
    border: 1px solid rgb(83, 83, 83);
  }
  #rad{
    margin-left: 10px;
  }
  .matched-answer {
    color:green;
    font-weight: 800;
  }
  
  .marked-answer {
    color:rgb(59, 10, 150);
    font-weight: 800;
  } 
  #button_plate{
    border-radius: 100%;
  align-content: center;
  border: 1px solid rgb(119, 116, 116);
  }
  .active{
    background-color:rgba(126, 5, 5, 0.925);
  }

  #section{
    background-color: rgb(129, 235, 198);
    padding: 5px;
    margin-right: 5px;
  }
  #submit_button{
   width:24%;
    font-weight: 600;
   text-align: center;
  }

  