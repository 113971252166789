
  #header_row{
    margin: 0px;
  }
  #linksidebar{
    text-decoration: none;
  }

  .side-panel.show {
    right: 0;
  }
  
  #instruction{
    background-color: white;
    margin-top: -60px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 15px;

  }