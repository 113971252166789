#h3{
    color:  #118585;
    padding: 0px;
}
#nodata{
    color: #0c3131;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    margin-top: 80px;
}
#linkh{
    text-decoration: none;
    padding: 5px; 
    color:  #118585;  
    font-weight: 500;
    font-size: 14px;
}#linkh:hover{
    text-decoration: none;
    background-color: #118585;
    padding: 5px;   
    color: white;
}

#linkbutton{
    float:right;
    margin-bottom: 3px;
}
#buttoncs:hover{
    background-color: #0c3131;
    border: none;
    outline: none;
}
#buttoncs{
    background-color: #118585;
    border: none;
    outline: none;
    color: white;
}