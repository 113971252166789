/* General container styling */
  #toggle{
    float: right;
    background-color: white;
    color: black;
    margin-top: 25px;
    border-color: white;
  }
  /* Sidebar Styling */
  .sidebar {
    position: fixed;
    margin-top: 76px;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: white; /* Dark sidebar background */
    color:black;
    overflow-y: auto;
    padding: 20px;
    transition: all 0.5s ease-in-out; /* Smooth transition */
    z-index: 1000;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .sidebar-hide {
    left: -250px; /* Hidden state */
  }
  
  .sidebar-show {
    left: 0; /* Visible state */
  }
  
  /* Sidebar Content */
  .sidebar-content ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-link {
    color: black;
    text-decoration: none;
    display: block;
    padding: 10px;
    margin: 5px 0;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .sidebar-link:hover {
    background-color: #9e9fa1;
    color: white;
    text-decoration: none;
  }
  
  
  .sidebar-toggle-btn:hover {
    float: right;
  }
  
  .btn-login, .btn-logout {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .btn-login:hover, .btn-logout:hover {
    background-color: #9c9ea0;
  }
  
  /* Responsive Design */
  @media (max-width: 767px) {
    .sidebar {
      width: 200px;
    }
  
    .sidebar-hide {
      left: -200px; /* Adjust hidden state for smaller devices */
    }
  
   
  }
  
  