.user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  
  .profile-card {
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
  }
  
  .profile-card h6 {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #343a40;
  }
  
  .profile-card p {
    font-size: 14px;
    color: #6c757d;
    margin: 5px 0;
  }
  
  .profile-card .fa-user-circle {
    font-size: 80px;
    color: #6c757d;
  }

  
  .content-container {
    overflow-y: scroll;
    height: 635px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .language-selector {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .language-selector label {
    margin-right: 10px;
    font-weight: bold;
    color: #555;
  }
  
  .language-warning {
    position: fixed;
   
   
    transform: translateX(-50%);
    width: 100%;
    background-color: #ffe0e0;
    border-radius: 8px;
    padding: 10px 20px;
    text-align: center;
    color: #a00;
    font-weight: bold;
  }
  
  .start-test-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;
  }
  
  .start-test-container button {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
  }
  