.animated-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030; /* Ensures it stays above other content */
    background-color: #ffffff; /* White background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for subtle elegance */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Times New Roman', Times, serif;
}

.animated-navbar:hover {
    background-color: #f8f9fa; /* Slightly off-white for hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.navbar-brand {
    display: flex;
    align-items: center;
    font-size: 1.6rem; /* Slightly larger for better branding */
    font-weight: bold;
    color: #0e7743; /* Dark gray for professional look */
    transition: transform 0.3s ease;
    font-family: 'Times New Roman', Times, serif;
}

.navbar-brand:hover {
    transform: scale(1.1);
}

.navbar-logo {
    height: 50px; /* Increased size for better visibility */
    margin-right: 10px;
}

.animated-nav-links .nav-link {
    font-size: 1.1rem; /* Comfortable size for readability */
    margin: 0 12px;
    color: #343a40; /* Dark gray for a clean, modern look */
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
}

.animated-nav-links .nav-link:hover {
    color: #011608; /* Attractive blue hover color */
}

.animated-nav-links .nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #888b8a; /* Blue underline for hover */
    transition: all 0.3s ease;
}

.animated-nav-links .nav-link:hover::after {
    width: 100%;
    left: 0;
}

.animated-dropdown .dropdown-item {
    font-size: 1rem;
    color: #343a40; /* Consistent dark gray */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.animated-dropdown .dropdown-item:hover {
    background-color: #eaecec; /* Blue background on hover */
    color: #0f0f0f; /* White text on hover */
}
