.list-group-item {
   
 
    background-color: white;
  }
  
  .body{
    background-color: white;
  }
  /* Hover effect for topic names */
.hover-highlight:hover {
  color: #ff004c; /* Blue color */
  text-decoration: underline;
  font-weight: bold;
  transition: color 0.3s, font-weight 0.3s;
}

/* Highlight latest jobs */
.highlight-latest {
  background-color: #fcf4fb;
  color: #ff004c; 
  border-radius: 8px;
  padding: 0px 3px 0px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
}

.highlight-latest:hover {
  transform: scale(1.02);
  background-color: #eef7ff;
}
