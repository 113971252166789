#title{
    color: rgb(2, 87, 87);
    font-weight: 600;
}
#home{
    font-family: 'Times New Roman', Times, serif;
    background-color: white;
    
}

body {
    padding-top: 60px; /* Adjust to the navbar height */
    background-color: white;
}


