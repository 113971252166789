/* General Background and Card Style */
.bg-light {
    background-color: #f8f9fa !important;
  }
  
  .card {
    border-radius: 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  /* Image Styling */
  .card-img-top {
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .card-img-top:hover {
    transform: scale(1.05);
  }
  
  /* Title and Description */
  .card-title {
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  
  .topic-description {
    color: #555;
    line-height: 1.8;
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  /* Button Styles */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .btn-link {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: #007bff;
  }
  
  .btn-link:hover {
    text-decoration: underline;
  }
  
  /* Footer and Header Spacing */
  footer, header {
    margin-top: 40px;
  }
  