#rank{
    text-align: center;
    
    
    
}
#rank1{
    font-size: 30px;
    text-align: center;
    color: rgb(165, 7, 21);

  }
  #rank2{
    font-size: 30px;
    text-align: center;
    color: rgb(16, 6, 151);

  }
  #rank3{
    font-size: 30px;
    text-align: center;
    color: rgb(8, 163, 184);

  }
  #rank4{
    font-size: 30px;
    text-align: center;
    color: rgb(4, 143, 78);

  }
  #rankh{
    font-size: 30px;
    text-align: center;
    color: cadetblue;

  }