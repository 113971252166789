#card {
    /* background-image: linear-gradient(to top, rgb(239, 245, 243), white, #022929); */
    
    margin-top: 20px;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    color:white;
  }

  #cardp{
    font-size: 12px;
    color: rgb(236, 231, 231);
  }
  
#h1{
    background-image: linear-gradient(to top, white , #012b2b);
  color: aliceblue;
  padding: 10px;
  margin-top:20px ;
}
#h3{
    color: #118585;  ;
    margin-top: 40px;
    margin-bottom: 20px;
}
#href{
    text-decoration: none;
}
#button{
   
    position: relative;
    margin-top: 40px;
    width: 100%;
    margin-bottom:15px;
    border-radius: none;
}
#button:hover{
    background-color: #4fbdbd;
    border: none;
}
#row{
    margin: 0px !important;
   /* margin-left: -15px !important;*/
    margin-right: 0px !important;
    margin-left: 0px !important;
   
    
    
}
 #titlep{
  background-color: rgb(4, 88, 84);
  color: white;
  padding:0 2px 0 2px;
  border-radius: 5px;
  font-size: 10px;
 
  margin-left: 20px;
 }
 