/* Button Style */
.btn-primary {
    background-color: #007bff;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  /* Button Container */
  button-bar {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
  }

  #next_button{
      padding: '5px 10px';
      font-size: '16px';
      font-weight: 'bold';
      border-radius: '4px';
      box-shadow: '0 4px 8px rgba(0, 0, 0, 0.2)';
      float: right;
     

  }

  #toggle_button{
    float: right;
    margin-top: 5px;
    border-color: none;
    color: rgb(24, 23, 23);
    background-color: white;

  }

  .correct-answer {
    background-color: green !important;
    color: white;
  }
  
  .incorrect-answer {
    background-color: red !important;
    color: white;
  }
  
  