

/* Footer Background */
.footer-bg {
  
    background-color: #011218;
    color: #f1f1f1;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  /* Footer Brand Styling */
  .footer-brand h5 {
    color: #fff;
    font-weight: bold;
  }
  
  /* Footer Links */
  .footer-link {
    color: #f1f1f1;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .footer-link:hover {
    color: #3498db;
    transform: translateX(5px);
  }
  
  /* Footer Divider */
  .footer-divider {
    background-color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  /* Footer Text */
  .footer-text {
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    font-weight: lighter;
  }
  
  /* Hover Animations for Social Media Links */
  .footer-link:hover {
    color: #3498db;
    transform: scale(1.05);
  }
  
  /* Mobile Responsiveness for Footer */
  @media (max-width: 768px) {
    .footer-bg {
      text-align: center;
    }
  
    .footer-link {
      display: block;
      margin-bottom: 10px;
    }
  }
  